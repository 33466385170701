<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Training Evaluation Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <!-- <div class="d-flex"> -->
              <div>
                <label class="col-form-label me-1">Training Date</label>
                <div style="width: 150px">
                  <flat-pickr
                    v-model="training_date"
                    class="form-control"
                    placeholder="Select Training Date"
                    name="date"
                    @change="
                      getFilterData(
                        training_date,
                        moduleName,
                        status,
                        employee,
                        holding_bu
                      )
                    "
                  ></flat-pickr>
                </div>
              </div>

              <div class="ms-2">
                <label class="col-form-label ms-1 me-1">Module</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="moduleName"
                    :options="modules"
                    :clearable="false"
                    :selectable="(options) => options.id != moduleName.id"
                    @option:selected="
                      getFilterData(
                        training_date,
                        moduleName,
                        status,
                        employee,
                        holding_bu
                      )
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div class="ms-2">
                <label class="col-form-label ms-1 me-1">Status</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="status"
                    :options="statusTypes"
                    :clearable="false"
                    :selectable="(options) => options.id != status?.id"
                    @option:selected="
                      getFilterData(
                        training_date,
                        moduleName,
                        status,
                        employee,
                        holding_bu
                      )
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div class="ms-2">
                <label class="col-form-label ms-1 me-1">Employee Name</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="employee"
                    :options="employeeLists"
                    :clearable="false"
                    :selectable="(options) => options.id != employee?.id"
                    @option:selected="
                      getFilterData(
                        training_date,
                        moduleName,
                        status,
                        employee,
                        holding_bu
                      )
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div class="ms-2">
                <label class="col-form-label ms-1 me-1">HoldingBusiness</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="holding_bu"
                    :options="holdingBusiness"
                    :clearable="false"
                    :selectable="(options) => options.id != holding_bu.id"
                    @option:selected="
                      getFilterData(
                        training_date,
                        moduleName,
                        status,
                        employee,
                        holding_bu
                      )
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div @click="refreshData" class="icon-css pt-4">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>

              <!-- </div> -->
            </div>
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive mt-4" v-if="!loading">
              <table
                class="table table-striped dt-responsive mb-3"
                id="dataTable"
                v-show="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Employee Name</th>
                    <th>Position Level</th>
                    <th>Department</th>
                    <th>Holding Business</th>
                    <th>Module Title</th>
                    <th>Training Date</th>
                    <th>Expired Date</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, index) in allDb" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.employee_name }}</td>
                    <td>{{ item.position_level }}</td>
                    <td>{{ item.department }}</td>
                    <td>{{ item.business_unit }}</td>
                    <td>{{ item.module }}</td>
                    <td>{{ item.from_date }}</td>
                    <td>{{ item.expired_date }}</td>
                    <td>{{ item.status }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net/js/jquery.dataTables.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
import moment from "moment";
import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
    flatPickr,
    badgeSuccess,
  },
  data() {
    return {
      training_date: "",
      moduleName: "",
      holding_bu: "",
      employee: "",
      status: "",
      allDb: [],
      employeeLists: [],
      modules: [],
      holdingBusiness: [],
      statusTypes: [
        { id: 1, name: "Complete" },
        { id: 2, name: "Expired" },
      ],
      headers: [
        "No",
        "Employee Name",
        "Position Level",
        "Department",
        "Holding Business",
        "Module Title",
        "Training Date",
        "Expired Date",
        "Status",
      ],
      title: "Training Evaluation Report",
      loading: false,
      pdfLoading: false,
      excelLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  methods: {
    async getAllModules() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
        this.modules = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },
    async getAllBusinessUnits() {
      await axios
        .get(`${this.baseUrlHRIS}api/holdingbusiness`)
        .then((response) => {
          this.holdingBusiness = response.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Not Bu/Br found.");
        });
    },
    async getAllEmployee() {
      this.loading = true;
      await axios.get(`${this.baseUrlHRIS}api/employees`).then((response) => {
        this.employeeLists = response.data.data;
        this.loading = false;
      });
    },
    async getAllModules() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
        this.modules = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },
    getFilterData(training_date, moduleName, status, employee, holding_bu) {
      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        let trainingDateFilter = training_date;
        let trainingDateStage = data[6];
        let moduleFilter = moduleName?.name;
        let moduleStage = data[5];
        let statusFilter = status;
        let statusStage = data[8];
        let holdingBuFilter = holding_bu?.name;
        let holdingBuStage = data[4];
        let employeeFilter = employee?.name;
        let employeeStage = data[1];
        if (
          (trainingDateFilter == trainingDateStage || !trainingDateFilter) &&
          (moduleFilter == moduleStage || !moduleFilter) &&
          (statusFilter == statusStage || !statusFilter) &&
          (holdingBuFilter == holdingBuStage || !holdingBuFilter) &&
          (employeeFilter == employeeStage || !employeeFilter)
        ) {
          return true;
        }
        return false;
      });
      $("#dataTable").DataTable().draw();
    },
    async getAllTrainingDb() {
      await axios
        .get(`${this.baseUrl}admin/v2/training-database-list`)
        .then((response) => {
          this.loading = true;
          this.$Progress.start();
          this.allDb = response.data.data;
          this.allDb.forEach((item) => {
            this.employeeLists.forEach((emplist) => {
              if (emplist.emp_id == item.employee_id) {
                item.employee_name = emplist.name;
                item.business_unit = emplist.business_unit[0]?.name;
                item.department = emplist.department[0]?.name;
                item.position_level = emplist.position_level[0]?.name;
              }
            });
          });
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error, "error value is");
        });
      //
      $("#dataTable").DataTable().draw();
    },
    exportPdfClick() {
      const dataArray = $("#dataTable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();

      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");

      this.pdfLoading = true;
      const sheetData = [];

      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        sheetData.push(item);
      });

      exportPdf(this.headers, sheetData, this.title);
      this.pdfLoading = false;
    },
    exportExcelClick() {
      const dataArray = $("#dataTable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();

      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");

      this.excelLoading = true;
      const sheetData = [];

      sheetData.push(this.headers);
      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        sheetData.push(item);
      });

      exportExcel(sheetData, "Training Evaluation Report");
      this.excelLoading = false;
    },
    refreshData() {
      this.training_date = "";
      this.moduleName = "";
      this.status = "";
      this.employee = "";
      this.holding_bu = "";
      this.allDb = [];
      this.clearFilter();
      this.getAllTrainingDb();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#dataTable").DataTable().draw();
    },
  },
  async created() {
    this.clearFilter();
    // this.allMonths = months;
    if (this.$store.getters["odoo/getAllEmployees"].length > 0) {
      this.employeeLists = this.$store.getters["odoo/getAllEmployees"];
    } else {
      await this.getAllEmployee();
    }
    this.getAllModules();
    this.getAllBusinessUnits();
    await this.getAllTrainingDb();
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
